import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanISendAttachmentsViaDm = ({ location }) => {
  const title = "即时中奖结果通知中可以附加图片吗？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="Twitter即时赢取活动可以将图片、视频、Excel和其他文件作为赠品的一部分分发。 (需要进行一些定制）。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              我可以在中奖结果通知的直接信息中发送附件吗？
            </h1>
            <p className="qa-page__answer">
              不可能在PARKLoT系统自动发送的直接信息中指定附件。
            </p>
            <p className="qa-page__text">
              如果你想使用PARKLoT功能发送图片或视频等附件，你需要定制它。
            </p>
            <p className="qa-page__text">
              如果你有数据或材料想发给参与者，我们建议使用GoogleDrive这样的存储服务。
            </p>
            <p className="qa-page__text">
              尝试获得一个可分享的链接，并将链接作为信息发送。
            </p>
            <div>
              <h3 className="qa-page__h3">
                我可以自定义发送给参与者的直接信息的措辞吗？
              </h3>
              <p className="qa-page__text">
                是的，向活动参与者发送的结果通知直接信息的措辞可以从管理屏幕上改变。
              </p>
              <p className="qa-page__text">URL链接也可以通过直接信息发送。</p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanISendAttachmentsViaDm
